.bgImg-simplePay {
    background-image: var(--app-bg-sph-left-image),
        var(--app-bg-sph-right-image);
    background-position: left top, right bottom;
    background-repeat: no-repeat no-repeat;
}

.bgImg-coupe {
    background-image: var(--app-bg-coupe-left-image),
        var(--app-bg-coupe-right-image);
    background-position: left top, right bottom;
    background-repeat: no-repeat no-repeat;
}
