.marker-cluster-label {
    position: absolute;
    top: 110px;
    left: -8px;
    background: #fff;
}
.group-modal-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.group-modal-container img {
    width: 50px;
    height: 50px;
}
@media screen and (max-width: 780px) {
    .group-results-modal-container {
        width: 90% !important;
    }
}
