.physician-content-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
}
.physician-content-container > div {
    width: 50%;
    text-align: center;
}
.mx-0 {
    margin-left: 0;
    margin-right: 0;
}
.physician-middle-cont > b {
    font-size: 16px;
    color: green;
    margin-top: 10px;
    display: block;
}
.physician-middle-cont {
    text-align: center;
}
.Office-Hours,
.out-of-pocket {
    font-size: 16px;
    padding-top: 10px;
}
.Office-Hours {
    margin-bottom: 22px;
}

.checklist-card > div {
    padding-top: 10px;
    padding-bottom: 10px;
}
.checklist-card > div > b {
    padding-top: 10px;
    padding-bottom: 10px;
}
.left-icon a,
.right-icon a,
.physician-content-container .EventAvailableIcon {
    color: var(--bg-primary);
}
.card-list-footer-action .card-footer-secondary div {
    padding-right: 10px;
}
.PhysicianDetails-landing .tier-container {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tier-container {
    position: relative;
}
.onClose-icon {
    z-index: 999;
}
.final-tier {
    line-height: 40px;
    height: 40px;
    width: 40px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 999999;
    color: #fff;
    text-align: center;
}
.modal-subtext-final-tier {
    font-size: 0.8em;
    width: 40px;
    opacity: 0.8;
}

.desig-left-section {
    display: flex;
    margin-bottom: 0.5rem;
    width: calc(100% - 100px);
}
.PhysicianDetails-landing .final-tier {
    left: -5px;
}
.PhysicianDetails-landing .desig-left-section {
    align-items: center;
    justify-content: space-between;
}
.PhysicianDetails-landing .desig-seconday-section > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.PhysicianDetails-landing .desig-seconday-section > div > svg {
    margin-right: 14px;
}
.PhysicianDetails-landing .desig-seconday-section-cont > div:first-child {
    margin-bottom: 8px;
    margin-top: 5px;
}
.PhysicianDetails-landing .desig-seconday-section-cont div:nth-child(2) {
    margin-bottom: 0;
    padding-bottom: 0;
}
.PhysicianDetails-landing .StarIcon-icon {
    width: 30px;
    height: 30px;
}
.procedureVisitListModal > div:first-child {
    padding-top: 10px;
}
.procedureVisitListModal > div {
    padding-bottom: 10px;
}
.procedureVisitListModal > div b {
    color: green;
}
.facilityResultListModal {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 1rem;
}
.facilityIcon-simplePay {
    fill: var(--bg-primary-purple);
}
.facilityIcon-coupe {
    fill: var(--bg-primary-black);
}
