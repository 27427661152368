.countDownModal .idleTimer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countDownModal .idleTimerExp {
    font-size: 22px;
}

.countDownModal .idleTimertxt {
    color: black;
}

.countDownModal .idleTimertimer {
    font-size: 40px;
}

.countDownModal .MuiBox-root {
    background: white !important;
}

.countDownModal .yesBtn {
    background-color: var(--bg-primary) !important;
    height: 40px;
    width: 100%;
}

.countDownModal .timer {
    font-size: 20px;
    font-weight: 700;
}

.countDownModal .timeout-div {
    margin-bottom: 12px;
}

.countDownModal .timerBox .countDownTimer .exp {
    margin-left: 86px;
}

.countDownModal .timerBox svg {
    margin-left: 126px;
}

.countDownModal .routeChange {
    right: 4%;
    position: absolute;
    cursor: pointer;
    transform: translate(0%, -50%);
    padding: 4px 8px;
}

.countDownModal .routeChange:hover {
    background: #bbb;
}

.countDownModal .idleTimerExp {
    font-size: 14px;
}
