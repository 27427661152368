.ProviderResults-landing {
    height: 100%;
    background-image: var(--bg-left-wave), var(--bg-right-wave);
    background-repeat: no-repeat;
    background-position: top left, bottom right;
}
.ProviderResults-landing .providers-left {
    width: 40%;
    margin: 0 20px;
    height: 100%;
    overflow: scroll;
}
.ProviderResults-landing .providers-right {
    width: 60%;
    height: 100%;
    padding: 10px 10px;
}
.providers-mobile-right {
    width: 100%;
    height: 100%;
}
.provider-inner-contianer {
    height: 100%;
    margin-top: 20px;
}
.provider-inner-contianer .providers-right {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
.ProviderResults-card {
    width: 100%;
    max-width: 40%;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 24px;
    border-radius: 2px;
    margin-bottom: 1rem;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 0px 0px 0px var(--bg-primary),
        0px 1px 1px 1px var(--bg-primary), 3px 1px 7px 3px var(--bg-primary) !important;
    margin-left: 2px;
}
.provider-inner-contianer .card-wrapper .last-element {
    margin-bottom: 0;
}
.ProviderResults-landing .mat-card-header {
    display: flex;
    flex-direction: row;
}
.ProviderResults-landing .result-card-title {
    text-align: left;
    justify-content: space-between;
    font-size: 16px;
    padding-left: 0;
}
.ProviderResults-landing .badge-margin {
    margin-right: 50px !important;
}
.ProviderResults-landing .mat-card-subtitle {
    font-size: 16px;
}

.ProviderResults-landing .mat-card-subtitle {
    color: rgba(0, 0, 0, 0.54);
}
.ProviderResults-landing .mat-card-subtitle,
.mat-card-title {
    display: block;
    margin-bottom: 16px;
}
.result-card-title {
    text-align: left;
    justify-content: flex-start;
    font-size: 16px;
}
.ProviderResults-landing .mat-card-content,
.ProviderResults-landing .mat-card-header .mat-card-title,
.ProviderResults-landing .mat-card-subtitle {
    font-size: 16px;
}
.alignment-mat-icon {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}
.desig-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}
.card-footer-secondary {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
    margin-top: 0.5rem;
}
.StarIcon-icon,
.desig-left-icon {
    height: 40px !important;
    position: relative;
    width: 40px;
}
.desig-seconday-section {
    margin: 0 8px;
}
.ProviderResults-landing .name-flds {
    font-weight: bold;
    color: black;
}
.card-footer-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.card-footer-action button {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary) !important;
    padding: 0 15px;
    height: 40px;
}
.ProviderResults-landing .card-action {
    padding-left: 0;
    margin-top: 0.5rem;
}
.ProviderResults-landing .EventAvailableIcon {
    color: var(--bg-primary);
    /* color: #fff; */
}
.ProviderResults-landing .CheckCircleIcon {
    margin-right: 0.5rem;
}

.tier-container {
    /* position: absolute; */
    /* z-index: 1; */
    /* right: 0; */
    text-align: center;
    /* width: 20%; */
}
.non-prefer .tier-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.final-tier {
    position: absolute;
    z-index: 9;
    color: #fff;
    text-align: center;
}
.subtext-final-tier {
    font-size: 0.8em;
    opacity: 0.8;
}
.cards-heading {
    padding-top: 15px;
    padding-bottom: 5px;
}
.cards-heading .provider-title {
    font-weight: bold;
}
.seach-doctor-head {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #292b2c;
}
.simplePay-health .cards-heading,
.simplePay-health .seach-doctor-head,
.coupe-health .cards-heading,
.coupe-health .seach-doctor-head {
    z-index: 999;
    position: relative;
}
.simplePay-health .cards-heading,
.simplePay-health .seach-doctor-head {
    color: var(--text-common);
}
.results-workflow-container .MuiButtonBase-root.MuiButton-root {
    color: var(--text-primary);
    background-color: var(--bg-primary);
    width: 100%;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 10px;
}
.ProviderResults-landing div[role="tablist"] > button {
    width: 50%;
    min-width: 50%;
    background-color: #fff;
}

.ProviderResults-landing div[role="tablist"] > button.Mui-selected {
    background-color: #e2e3e4;
}
.ProviderResults-landing div[role="tablist"] > button:first-child {
    border-right: 1px solid #eceff1;
}
div[role="tabpanel"] {
    padding: 0;
    height: 100%;
    overflow: auto;
}
.preferred-card .StarIcon-icon img,
.preferred-card .desig-left-section img {
    width: auto;
    height: 40px;
}
.provider-results-modal-container .desig-left-section {
    width: 100%;
}
.preferred-card .subtext-final-tier {
    left: -10px;
    font-weight: bold;
}
@media screen and (min-width: 480px) {
    .provider-results-modal-container {
        width: 350px !important;
    }
}

@media screen and (max-width: 767px) {
    .ProviderResults-landing {
        padding: 0 20px;
        height: 100%;
        background-size: contain;
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 768px) {
    .provider-results-modal-container {
        width: 500px !important;
    }
    .ProviderResults-landing {
        display: flex;
        overflow: auto;
        justify-content: space-between;
        align-items: flex-end;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .preferred-card .StarIcon-icon img,
    .preferred-card .desig-left-section img {
        width: 66px;
    }
}
.procedureVisitList > div {
    padding-bottom: 10px;
}
.facilityResultList {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 1rem;
}
.facilityIcon-color {
    fill: var(--bg-primary);
}
