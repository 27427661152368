
.pharmacies-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 25px 0px;
    height: auto;
}
.pharmacies-container .pharmacy-column {
    box-sizing: border-box;
    height: auto;
}
.pharmacies-container .final-tier {
    position: absolute;
    top: -6px;
    z-index: 9;
    left: -5px;
    color: var(--text-primary-simplePay);
    text-align: center;
    font-size: 15px;
}