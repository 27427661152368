.no-autocomplete {
    color: #999;
    padding: 8px;
}
.autocomplete {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
}
.autocomplete .list-item {
    padding: 8px;
}
.autocomplete > .active,
.autocomplete .list-item:hover {
    background-color: darkgray;
    cursor: pointer;
    font-weight: 700;
}
.autocomplete .list-item:not(:last-of-type) {
    border-bottom: 1px solid #999;
}
.MuiInputBase-input,
.MuiInputBase-root.MuiInput-root,
.MuiFormControl-root.MuiTextField-root,
.autoComplete-input-wrapper {
    width: 100% !important;
}
.autoComplete-input-wrapper .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--bg-primary);
    outline: 0;
    font-size: 16px;
    color: #212121;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.autoComplete-input-wrapper .MuiInput-root:before,
.autoComplete-input-wrapper .MuiInput-root:after,
.autoComplete-input-wrapper .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom-color: var(--bg-primary) !important;
    color: var(--bg-primary);
}

.typeAheadGroupingMessage {
    padding: 15px 0 0 20px;
    color: black;
    font-size: 16px;
}
.typeAheadResultHeaderFont {
    color: var(--bg-primary);
    font-weight: 700;
}
.autoComplete-wrapper,
.autoComplete-input {
    position: relative;
}

.autoComplete-input > div > label.MuiFocused,
.autoComplete-input > div > label {
    color: var(--text-common) !important;
}
.autoComplete-input-icon {
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    color: gray;
    cursor: pointer;
    z-index: 99;
}
.dropdown-menu {
    display: block;
    float: none;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 1px 0px 13px 0px rgb(0 0 0 / 20%), 0 6px 5px 0 rgb(0 0 0 / 19%);
    border-radius: 0;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    width: 100%;
    border: none;
    max-height: 300px;
    overflow-y: auto;
}

.dropdown-menu a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
}
.viewMore {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.viewMore div {
    font-weight: bold;
    font-size: 14px;
    color: var(--bg-primary);
    padding-right: 3px;
    padding-left: 20px;
}
.padL5 {
    padding-left: 5px;
    height: 9px;
    width: 13px;
}

.viewMore img {
    max-width: 100%;
}
.typeahead_separator {
    height: 1px;
    background: #a1a1a1;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (max-width: 767px) {
    .autoComplete-input .MuiInputBase-root.MuiInput-root {
        font-size: 14px;
    }
    .autoComplete-input-fld input {
        padding-top: 11px;
        padding-bottom: 11px;
    }
    .autoComplete-input-fld input::placeholder {
        font-size: 12px;
    }
}
.autocomplete-info-icon {
    font-size: 12px !important;
    margin-right: 4px;
}
