.landing {
    background-image: var(--bg-left-wave), var(--bg-right-wave);
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    flex: 1;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-search.landing {
    z-index: 2;
    background-image: none;
    position: fixed;
}
.nav-search .main-landing > div {
    position: fixed;
}
.main-landing {
    overflow: auto !important;
    margin: 20px 0;
}
.main-landing [role="dialog"] {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
}
.main-landing [role="dialog"] > div {
    min-height: 320px;
    overflow: auto;
    max-height: initial;
}
.auto-complete-expand .main-landing [role="dialog"] > div {
    min-height: 430px;
    overflow: auto;
}
.main-landing > div {
    top: 50px;
    position: static;
}
.contact-link,
.contact-link:hover {
    color: var(--bg-primary);
}
.loanding-loader {
    margin-top: 100px;
    width: 100%;
    text-align: center;
}
.category-label {
    padding: 1rem 0;
    font-weight: bold;
}

.new-search-close-icon {
    position: relative;
    top: 4px;
}
.tab-parent-section .MuiTabs-flexContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.tab-parent-section .MuiTabs-flexContainer > button {
    width: 50%;
    background-color: #f5f5f5;
    cursor: unset;
}

.tab-parent-section .MuiTabs-flexContainer > button:hover,
.tab-parent-section .MuiTabs-flexContainer > button:focus {
    outline: none;
}
.tab-parent-section .Mui-selected {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary) !important;
}
.tab-parent-section .MuiTabs-indicator {
    height: 0 !important;
}
@media screen and (max-width: 767px) {
    .navbarSearchModalContainer {
        position: absolute !important;
    }
    .landing {
        background-size: contain;
    }
    .page-header > div {
        padding: 0;
    }
    .searchModalContainer .MuiDialog-container {
        margin: 0 20px;
    }
    .searchModalContainer .MuiDialog-container > div > div,
    .searchModalContainer .MuiDialog-container > div {
        width: 100%;
        min-width: 100%;
        overflow: auto;
    }
    .searchModalContainer .MuiDialog-container > div > div > div {
        max-width: 100% !important;
    }
    .main-landing [role="dialog"] > div {
        max-width: 100%;
        min-width: 280px;
        width: 100%;
    }
    .main-landing .form__field {
        font-size: 14px;
    }
    .main-landing .form__field:placeholder-shown ~ .form__label {
        font-size: 14px;
    }
}
@media screen and (max-width: 374px) {
    .main-landing .form__field:placeholder-shown ~ .form__label {
        font-size: 12px;
    }
    .main-landing [role="dialog"] > div {
        min-width: 100%;
    }
}

@media screen and (min-width: 767px) {
    .main-landing [role="dialog"] > div {
        width: 100%;
    }
    .new-search-close-icon {
        top: -4px;
    }
}

@media screen and (min-width: 1024px) {
    .new-search-close-icon {
        left: 10px;
    }
}
@media screen and (min-width: 1400px) {
    .main-landing div[role="dialog"] > div {
        min-width: 570px;
    }
}
@media screen and (min-width: 1800px) {
    .navbarSearchModalContainer .MuiDialog-paper {
        min-width: 600px !important;
    }
    .navbarSearchModalContainer .MuiDialog-paper .content-wrapper {
        min-width: 500px;
        max-width: 700px;
    }
}
section.full-tab-width button {
    width: 100% !important;
    min-width: 100%;
}
