.GenerateProviderReport-landing {
    height: 100%;
    background-image: var(--bg-left-wave), var(--bg-right-wave);
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    padding: 1rem 1rem 0.5rem;
    .GenerateProviderWrapper {
        height: 100%;
        width: 100%;
        .inner-cont {
            overflow: auto;
            height: 100%;
            max-height: 100%;
            background: #fff;
            /* [role="grid"] {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            .MuiDataGrid-main {
                box-ordinal-group: 2;
                -webkit-box-ordinal-group: 2;
            }
        } */
        }
    }
    .MuiDataGrid-columnHeaders {
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.print-btn {
    background-color: var(--bg-primary) !important;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    text-transform: capitalize;
}

.GenerateProviderWrapper div.MuiDataGrid-footerContainer > div:last-child {
    display: none;
}
@media print {
    .MuiDataGrid-root {
        height: auto !important;
        max-height: none !important;
        overflow: visible !important;
    }
    body,
    html,
    .App-header {
        position: static;
        overflow: visible !important;
        height: auto !important;
    }
    .MuiDataGrid-footerContainer,
    .print-btn,
    .nav-bar,
    .page-header,
    .page-footer {
        display: none !important;
    }
    .App-header {
        position: static;
    }
    .MuiDataGrid-row {
        display: none !important;
        &.Mui-selected {
            display: flex !important;
            max-height: fit-content !important;
            min-height: auto !important;
            height: auto !important;
        }
    }

    .MuiDataGrid-row.Mui-selected {
        display: flex !important;
        max-height: fit-content !important;
        min-height: auto !important;
        width: auto;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        & > div {
            border: none;
        }
    }
    .GenerateProviderWrapper {
        height: auto !important;
        width: 100% !important;
        overflow: visible !important;
        min-height: auto !important;
        /* .MuiDataGrid-virtualScrollerContent {
            height: auto !important;
        } */
        .MuiDataGrid-columnHeaders {
            .MuiDataGrid-columnHeaderCheckbox {
                display: none !important;
            }
        }
        .MuiDataGrid-cellContent {
            overflow: visible;
            text-overflow: unset;
            word-wrap: break-word;
            white-space: break-spaces;
        }
        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            width: auto !important;
            max-width: 110px !important;
            min-width: 110px !important;
            overflow-wrap: break-word;
            word-break: break-word;
            justify-content: flex-start !important;
            min-height: auto !important;
            padding: 10px !important;
            max-height: max-content !important;
            &[data-field="address_one"],
            &[data-field="provider_name"] {
                max-width: 180px !important;
                min-width: 180px !important;
            }
            &[data-field="default_rate_one"],
            &[data-field="default_tier"] {
                max-width: 70px !important;
                min-width: 70px !important;
            }
            .MuiDataGrid-columnHeaders {
                .MuiDataGrid-columnHeaderCheckbox {
                    display: none !important;
                }
            }
            .MuiDataGrid-cellContent {
                overflow: visible;
                text-overflow: unset;
                word-wrap: break-word;
                white-space: break-spaces;
            }
        }
    }

    .GenerateProviderWrapper.inner-cont {
        overflow: visible;
        height: auto !important;
        max-height: auto !important;
        min-height: auto !important;
        background: #fff;
    }

    .GenerateProviderWrapper .MuiDataGrid-footerContainer,
    .GenerateProviderWrapper .MuiDataGrid-cellCheckbox {
        display: none !important;
    }

    .GenerateProviderWrapper .MuiDataGrid-columnHeaders {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }

    @page {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    body {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
