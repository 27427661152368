.dropdown-menu-ds {
    display: block;
    float: none;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 0px 8px #bdbdbd;
    border-radius: 8px;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    width: 100%;
    border: none;
    max-height: 300px;
    overflow-y: auto;
}
/* Custom scroll bar styles */
.dropdown-menu-ds::-webkit-scrollbar {
    width: 8px; /* Width of the scroll bar */
}

.dropdown-menu-ds::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scroll bar track */
    border-radius: 8px;
}

.dropdown-menu-ds::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scroll bar thumb */
    border-radius: 8px;
}

.dropdown-menu-ds::-webkit-scrollbar-thumb:hover {
    background: #aaa; /* Color of the scroll bar thumb on hover */
}
.dropdown-menu-ds a {
    text-transform: capitalize;
    justify-content: space-between;
    align-items: baseline;
    padding: 4px 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.42857143;
    color: #888;
    overflow: hidden;
    text-decoration: none;
    background: #fff;
    border-top: #eee 1px solid;
}
.dropdown-menu-ds .typeAheadGroupingMessage-ds:first-child a {
    border-top-width: 0;
}
.dropdown-menu-ds a:hover {
    background: #f5f5f5;
    color: #555;
    cursor: pointer;
}
.dropdown-menu-ds .drug-label {
    position: relative;
    top: -3px;
}
.dropdown-menu-ds .tiering-wrapper {
    margin-top: 4px;
}
.typeAheadGroupingMessage-ds {
    color: black;
    font-size: 16px;
}

.basicDrugSrc .Preventative,
.basicDrugSrc .Generic,
.basicDrugSrc .Preferred,
.basicDrugSrc .Non-Preferred,
.basicDrugSrc .maintainance,
.basicDrugSrc .Specialty {
    float: right;
}

/* Common styles for all badges */
.tier-badge {
    color: var(--text-primary-simplePay);
    border-radius: 5px;
    padding: 0.04em 0.08em;
    text-align: center;
    font-size: 75%;
    font-weight: 600;
    outline: none;
    border-width: 0;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 400;
    margin-right: 4px;
}

/* Specific styles for each badge type */
.maintainance {
    background-color: #996666;
    border-color: #996666;
}
.Preventative {
    background-color: #28a745;
    border-color: #28a745;
}

.Generic {
    color: #090614;
    background-color: #f6de30;
    border-color: #f6de30;
}

.Preferred {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Non-Preferred {
    background-color: #4d4d4d;
    border-color: #4d4d4d;
}

.Specialty {
    background-color: #ff3333;
    border-color: #ff3333;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem !important;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    line-height: 1.2rem;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #424242;
    background-color: var(--text-primary-simplePay);
    line-height: 1.2rem;
}

.MuiInputAdornment-root {
    position: absolute;
    right: 20px;
}
