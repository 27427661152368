.error-landing {
    background-image: var(--bg-left-wave), var(--bg-right-wave);
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    width: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-container {
    text-align: center;
    min-height: 220px;
    width: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
    padding: 0 24px;

    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.error-text {
    color: var(--text-common);
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1rem;
}

.error-landing button {
    padding: 0 15px;
    height: 40px;
}

.error-landing button,
.error-landing button:hover {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

@media screen and (max-width: 767px) {
    .error-container {
        max-width: 100%;
        min-width: 280px;
        width: 100%;
        min-height: 320px;
    }
}
