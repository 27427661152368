.page-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 0;
    position: sticky;
    background-color: #eceff1;
}

.page-footer p {
    font-size: 16px;
    margin-bottom: 0;
    color: var(--text-common);
    padding: 20px 0;
}

.page-footer p a {
    color: var(--text-common);
}

@media screen and (max-width: 767px) {
    .page-footer p {
        padding: 20px;
    }
}
