.PublicLanding-wrapper {
    background-image: url("../../assets/images/Coupe Internal Landing Image.png");
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    padding-top: 0;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PublicLanding-wrapper > div {
    width: 60%;
    margin: 0 auto;
}
@media screen and (max-width: 1500px) {
    .PublicLanding-wrapper > div {
        width: 80%;
    }
}
.PublicLanding-wrapper h2 {
    margin-bottom: 3rem;
}
.btn-wrapper button {
    margin-right: 1rem;
    width: 100%;
}

.continue-btn-wrapper {
    margin: 2rem 0;
}
.activeCoupe {
    background: #30f371 !important;
    color: #090614 !important;
}
.activeSimple {
    background: rgb(121 54 161) !important;
    color: #ffffff !important;
}
.drop-down-wrapper {
    & > div > p {
        width: 150px;
    }
}
