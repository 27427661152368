.pageNotFoundContainer {
    width: 100%;
    height: 100%;
    background-color: #d0fcfa;
}
#pageNotFound {
    display: flex;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
}
#img {
    background-image: url("../../assets/images/Black-Firework-Icon.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: fixed;
    background-position: bottom right;
    background-origin: border-box;
    position: relative;
    width: 100%;
    height: 100%;
}

.fof {
    position: absolute;
    bottom: 20%;
    /* left: 0; */
}
#pageNotFound img {
    position: absolute;
    right: 0px;
    height: auto;
    width: 55%;
    bottom: 0px;
    top: 5%;
    max-width: 100%;
    display: inline-block;
}

.fof h1 {
    font-size: 60px;
    display: inline-block;
    padding-right: 12px;
    /* animation: type 0.5s alternate infinite; */
    margin-left: 3rem;
    background: black;
    padding: 15px 30px;
    color: #fff;
    border-radius: 50px;
}
.fof p {
    margin-left: 3rem;
    font-size: 20px;
    color: black;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .fof h1 {
        margin-left: 2rem;
        font-size: 30px;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 1rem;
        display: flex;
        margin-top: 2rem;
        max-width: 300px;
        justify-content: center;
    }
    .fof p {
        margin-left: 2rem;
    }
    #img {
        flex: 1;
        width: 100%;
        background-size: auto;
        background-position: bottom center;
        background-attachment: unset;
    }
    .fof h1 {
        margin-top: 10rem;
    }
    .fof {
        position: static;
        flex: 1;
        margin: 0 auto;
    }
    #pageNotFound {
        align-items: baseline;
        flex-direction: column-reverse;
        padding-top: 2rem;
    }
}
@media screen and (min-width: 320px) and (max-width: 560px) {
    #img {
        max-width: 320px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 768px) and (max-width: 996px) {
    .fof h1 {
        font-size: 40px;
    }
    .fof p {
        max-width: 66%;
    }
    #pageNotFound {
        background-position: 100% 0%;
    }
}
@media screen and (min-width: 1024px) {
    #img {
        background-position-x: 70%;
    }
}
@media screen and (min-width: 1440px) {
    .fof {
        left: 23%;
    }
}
