.content-wrapper {
    padding: 8px;
    text-align: center;
}
.MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}
.search-bar-class > span {
    color: var(--bg-primary);
    width: 100%;
    margin-top: 14px;
}
div .search-bar-class > span span:nth-child(4) {
    left: 20px !important;
    color: var(--bg-primary-dark);
}
div .search-bar-class > span span:nth-child(6) {
    transform: translateX(-99%);
    -webkit-transform: translateX(-99%);
    color: var(--bg-primary-dark);
}
.criteria-workflow-title {
    font: 200 20px/26px Avenir, "Book Oblique";
    font-style: italic;
    color: var(--text-common);
    margin: 0 auto 20px;
    display: block;
    flex: 1;
    text-align: center;
    margin-top: 32px;
    width: 75%;
}

.shared-workflow-container {
    display: grid;
}
.shared-workflow-container .disclouser-btn-links {
    color: #707070;
    font-size: 10px;
    font-weight: normal;
    font-family: Avenir;
}
.shared-workflow-container .disclouser-btn-links svg {
    color: #939393 !important;
}

.shared-workflow-container button {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-size: x-small;
    text-align: center;
    color: var(--text-common);
}
.shared-workflow-container.help-icon svg {
    color: #1976d2;
}
.shared-workflow-container button a {
    color: var(--text-common);
}

.criteria-button .MuiButton-root,
.criteria-button .MuiButton-root:hover,
.criteria-button .MuiButton-root:focus,
.criteria-button .MuiButton-startIcon,
.ind-container button.btn-txt-color {
    background-color: var(--bg-primary);
    color: var(--text-primary) !important;
    border: none;
    border-radius: 10px;
}

.shared-workflow-container svg {
    color: var(--text-common) !important;
}

.shared-workflow-container button:hover,
.btn-txt-color:hover {
    background-color: #fff !important;
}

.find-container {
    cursor: pointer;
    min-height: 120px;
}

.fab-icon {
    background-color: var(--bg-primary) !important;
}

.criteria-button {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 5px;
}

.criteria-button button {
    text-transform: none !important;
}

.search-bar-class .MuiTextField-root {
    width: 100%;
    margin-bottom: 0.5rem;
}
.standard-field:-moz-placeholder,
.search-bar-class .MuiFormLabel-root.MuiInputLabel-root {
    color: var(--bg-primary);
}

.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before,
.search-bar-class .MuiInput-root:after,
.search-bar-class .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom-color: var(--bg-primary) !important;
    color: var(--bg-primary);
}

.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    margin-bottom: 1rem;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--bg-primary);
    outline: 0;
    font-size: 16px;
    color: #212121;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
    color: var(--text-common);
}

.form__label,
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}
.form__field:focus ~ .form__label {
    color: var(--text-common) !important;
}

.form__field:focus {
    padding-bottom: 6px;
    border-bottom: 2px solid var(--bg-primary);
}
.option-label {
    font-weight: bold;
    display: block;
}
.nav-search-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.location-search-subheader {
    font-weight: bold;
    margin-bottom: 50px;
}

@media all and (max-width: 767px) {
    .criteria-workflow-title {
        font: 200 18px/22px Avenir, "Book Oblique";
    }

    .criteria-button button {
        padding: 5px 14px;
    }
}
@media all and (max-width: 374px) {
    .locationFldsLbl {
        font-size: 12px !important;
    }
    .criteria-workflow-title {
        font: 200 18px/20px Avenir, "Book Oblique";
    }
}
@media all and (min-width: 767px) {
    .search-bar-class > span {
        width: 100%;
    }
}
.cursor-text {
    cursor: auto;
}
.location-search-input {
    border-radius: 20px !important;
}
