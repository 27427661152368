.page-header {
    width: 100%;
    background-color: #eceff1;
}

.navigation-logo {
    display: block;
    width: 150px;
    height: 50px;
    cursor: pointer;
}

.navigation-logo img {
    width: 100%;
    height: 100%;
}
.menu-item-li {
    min-height: 48px !important;
}
.menu-bar .MuiPaper-root {
    border-radius: 2px;
}
.menu-bar .MuiDivider-root {
    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.menu-bar .MuiSvgIcon-root {
    margin-right: 16px;
    vertical-align: middle;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
}
.menu-anchor {
    color: inherit;
}

.menu-anchor:hover {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    #long-menu ul {
        padding: 0 1rem;
    }
    #long-menu li {
        display: flex;
    }
    #long-menu li a {
        white-space: break-spaces;
    }
}
