.nav-bar {
    background: var(--bg-primary);
    display: flex;
    list-style-type: none;
    padding: 0.5rem;
    margin-bottom: 0;
}
.simplePay-health .nav-bar li button {
    color: var(--text-primary);
}
.nav-bar li button {
    color: var(--text-common);
}

.result-workflow-container {
    padding-top: 10px;
}
button.dismiss-btn {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary) !important;
    padding: 0 15px;
    height: 40px;
    width: 100%;
}
button.dismiss-btn.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.26) !important;
}
button.searchTip-dismiss-btn {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary) !important;
    padding: 0 15px;
    height: 40px;
    width: 100%;
}
@media screen and (max-width: 767px) {
    .nav-bar {
        justify-content: space-between;
        padding: 1rem;
    }
    .xs-hide {
        display: none;
    }
    .icon-bar-modal {
        width: 300px !important;
        word-break: break-all;
        height: 65%;
        overflow: auto;
    }
}
