* {
    padding: 0;
    margin: 0;
}

:root {
    --bg-primary-purple: #8331a7;
    --bg-primary-dark: #000000;

    --bg-light-image: url("./assets/images/SimplePay-background-img.png");
    --bg-dark-image: url("./assets/images/Coupe-theme-img.png");

    --bg-light-blue: #d0fcfa;
    --bg-dark-purple: #8331a7;

    --bg-primary-coupe: #32f371;
    --text-primary-coupe: #000000;

    --bg-primary-simplePay: #7936a1;
    --text-primary-simplePay: #ffffff;

    --app-bg-coupe-left-image: url("./assets/images/coupe-wave-left.svg");
    --app-bg-coupe-right-image: url("./assets/images/coupe-wave-right.svg");

    --app-bg-sph-left-image: url("./assets/images/sph-wave-left.svg");
    --app-bg-sph-right-image: url("./assets/images/sph-wave-right.svg");
}

.simplePay-health {
    --bg-primary: var(--bg-primary-simplePay);
    --text-primary: var(--text-primary-simplePay);
    --text-common: var(--text-primary-coupe);

    --bg-light: var(--bg-dark-purple);
    --bg-left-wave: var(--app-bg-sph-left-image);
    --bg-right-wave: var(--app-bg-sph-right-image);
}
.coupe-health {
    --bg-primary: var(--bg-primary-coupe);
    --text-primary: var(--text-primary-coupe);
    --text-common: var(--text-primary-coupe);

    --bg-light: var(--bg-light-blue);
    --bg-left-wave: var(--app-bg-coupe-left-image);
    --bg-right-wave: var(--app-bg-coupe-right-image);
}

/* @media screen and (min-width: 767px) { */
.App {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.App-header {
    position: sticky;
    top: 0;
    width: 100%;
}
.app-content {
    position: static;
    overflow: auto;
    width: 100%;
    flex: 1;
}

#map {
    height: 100%;
    width: 100%;
    margin: 0 20px 0 0;
    padding: 0;
}
.pac-container {
    z-index: 9999;
}

.setPosition {
    height: 100vh;
    margin-right: 28px;
    margin-top: 12px;
    color: var(--text-primary-simplePay) !important;
}
.provider-lable-flds {
    font-size: 12px;
    color: var(--text-common);
}
body div.App div button.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

body div.App button.Mui-disabled span svg,
body div.App button.Mui-disabled span {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
}
.search-outline-container input {
    padding-right: 45px !important;
}
.search-outline-container .MuiInputAdornment-root {
    position: absolute;
    right: 20px;
}
.search-outline-container > .MuiInputBase-root {
    padding-right: 5px;
    padding-left: 5px;
}
@media screen and (max-width: 767px) {
    .search-outline-container input {
        padding-top: 11px;
        padding-bottom: 11px;
    }
    #locationFlds::placeholder {
        font-size: 12px;
    }
}
