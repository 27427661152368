.linear-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: var(--bg-left-wave), var(--bg-right-wave);
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: contain;
    /* background-position-y: 100% !important; */
    background-color: #fff;
    flex: 1;
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: 100dvh;
    min-height: 100%;
    text-align: center;
    top: 0;
    z-index: 1000;
    left: 0;
    overflow: auto;
}
.progress-loading-screen-text-background {
    position: absolute;
    width: 100%;
    height: 100%;
}
.linear-progress [role="progressbar"] {
    width: 100%;
}
.loading-screen-group {
    margin-top: 30px;
}
.loading-text {
    font-size: larger;
    color: var(--text-common);
}
.linear-progress.coupe .loading-text {
    color: var(--text-common);
}
.app-progress-bar {
    width: 50% !important;
    margin-left: 25%;
}
.loading-text-alignment {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}
.app-progress-bar [role="progressbar"] {
    background-color: #f3e4ff;
}
.app-progress-bar [role="progressbar"] > span {
    background-color: var(--bg-primary);
}

.loading-screen-company-logo {
    background-image: url("../../../assets//images/SimplePay_logo_colored.svg");
    background-size: 22%;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    width: 100%;
    min-width: 100%;
    height: 45%;
    min-height: 45%;
}

.linear-progress.coupe .loading-screen-company-logo {
    background-image: url("../../../assets//images/Coupe-Green-bubble-logo.svg");
}

.content1 {
    opacity: 0;
    animation: reveal 1s 0.5s forwards;
}
.content2 {
    opacity: 0;
    animation: reveal 1s 1s forwards;
}
.content3 {
    opacity: 0;
    animation: reveal 1s 1.5s forwards;
}

@keyframes reveal {
    0% {
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 575px) {
    .loading-screen-company-logo {
        background-size: 15em;
    }
}
@media screen and (max-width: 767px) {
    .loading-screen-company-logo {
        background-size: 17em;
    }
}
