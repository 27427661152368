.alternatives-and-forms-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 0 40px;
    margin: 20px auto 20px;
}
.alternatives-and-forms-container .heading {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.alternatives-and-forms-container .raw-link{
    color: #555;
    font-size: 14px;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
}
.alternatives-and-forms-container .raw-link:hover{
    text-decoration: underline;
    color:#000;
}